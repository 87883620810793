import Device from './device';

const Image = {
  deleteInternalStorage(name, callback) {
    function errorHandler(err) {
      callback(err);
    }
    window.resolveLocalFileSystemURL(cordova.file.dataDirectory, (fileSystem) => {
      const relativePath = name.replace(fileSystem.nativeURL, '');
      fileSystem.getFile(relativePath, { create: false }, (fileEntry) => {
        if (!fileEntry) {
          callback();
          return;
        }

        fileEntry.remove(() => {
          callback();
        }, errorHandler);
      }, errorHandler);
    }, errorHandler);
  },
  getImage(callback, options = {}) {
    const cameraOptions = {
      sourceType: window.Camera.PictureSourceType.CAMERA,
      quality: 100,
      targetWidth: 4000,
      targetHeight: 4000,
      destinationType: window.Camera.DestinationType.FILE_URI,
      correctOrientation: true,
      allowEdit: false,
      encodingType: window.Camera.EncodingType.JPEG,
      saveToPhotoAlbum: true,
    }
    Object.assign(cameraOptions, options)
    console.log(cameraOptions)
    function onError(message) {
      console.log('Error capturing photo: '+message)
    }
    function fail(error) {
      callback(error);
    }
    function onSuccess(fileURI) {
      let URI = fileURI;
      // for some reason when selecting from Android gallery
      // the prefix is sometimes missing
      if (Device.isAndroid() &&
      cameraOptions.sourceType === window.Camera.PictureSourceType.PHOTOLIBRARY) {
        if (!(/file:\/\//).test(URI)) {
          URI = `file://${URI}`;
        }
        console.log(URI)
      }
      window.resolveLocalFileSystemURL(URI, callback, fail);
    }
    navigator.camera.getPicture(onSuccess, onError, cameraOptions);
  },
	dataURItoBlob(dataURI, fileType) {
	  let binary = atob(dataURI.split(',')[1])
	  let array = []
	  for (var i = 0; i < binary.length; i++) {
	    array.push(binary.charCodeAt(i))
	  }
	  return new Blob([new Uint8Array(array)], {type: fileType})
	},
}
export { Image as default };
