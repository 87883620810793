<template>
  <ion-app>
    <div id="app" v-if="standalone || isCordova || nodeEnv != 'production'">
      <ion-router-outlet />
    </div>
    <div class="appPaddings" v-else>
      <h1 style="font-size:1.2em">Die Flora-SH-App für Ihr Smartphone oder Tablet</h1>
      <p>Über die <strong>Flora-SH-App</strong> des Rote-Liste-Zentrums können botanische Erfassungen direkt im Feld oder nachträglich an das <strong><a href="https://flora-sh.rotelistezentrum.de/" target="_blank">Datenportal Flora von Schleswig-Holstein und Hamburg</a></strong> übermittelt werden. Dazu ist dort eine <strong>vorherige Registrierung</strong> erforderlich.
      Eine Erfassung von Daten, die außerhalb von Schleswig-Holstein und Hamburg erhoben werden, ist ebenfalls möglich.</p>
      <h1 style="font-size:1.2em">Vorschau</h1>
      <div class="rowi">
        <div class="columni">
          <img src="img/Screenshot_1_Flora_SH.png">
        </div>
        <div class="columni">
          <img src="img/Screenshot_2_Flora_SH.png">
        </div>
        <div class="columni">
          <img src="img/Screenshot_3_Flora_SH.png">
        </div>
      </div>
      <h1 style="font-size:1.2em">Installationsanleitung für die Browser Chrome (empfohlen) sowie Firefox und Edge</h1>
      <ol>
        <li>Öffnen Sie diese Seite auf Ihrem Smartphone oder Tablet.</li>
        <li>Tippen Sie auf das Menü rechts neben der Adressleiste und danach auf „Zum Startbildschirm hinzufügen“ (Chrome, Firefox) bzw. „Zum Smartphone hinzufügen“ (Edge).</li>
        <li>Nach Ihrer Bestätigung wird die App auf dem Smartphone oder Tablet installiert.</li>
      </ol>
      <h1 style="font-size:1.2em">Installationsanleitung für die Browser Safari und Firefox auf dem iPhone oder iPad</h1>
      <ol>
        <li>Öffnen Sie diese Seite auf Ihrem iPhone oder iPad.</li>
        <li>Tippen Sie auf das Teilen-Symbol und wählen Sie „Zum Home-Bildschirm“ aus. Auf Ihrem iPhone befindet sich das Teilen-Symbol in der unteren Menüleiste. Auf Ihrem iPad befindet sich das Teilen-Symbol rechts oben neben der Adressleiste.</li>
        <li>Bestätigen Sie die Installation, indem sie auf „Hinzufügen“ tippen.</li>
        <li>Nach Ihrer Bestätigung wird die App auf dem Smartphone oder Tablet installiert.</li>
      </ol>
      <h1 style="font-size:1.2em">Informationen zur Offline-Nutzung bei der Feldarbeit</h1>
      <p>Die Flora-SH-App kann zu jeder Zeit offline bei der Feldarbeit verwendet werden, um z. B. die Akkulaufzeit des Smartphones zu verlängern. 
      Bei der Installation werden die App-Inhalte im Hintergrund heruntergeladen. Eine Internetverbindung wird danach nur noch für Updates sowie das Hoch- und Herunterladen von Daten mit Attributen benötigt.</p>
    </div>
  </ion-app>
</template>
<script>
import { IonApp, IonRouterOutlet, useBackButton } from '@ionic/vue'
import { defineComponent } from 'vue'
export default defineComponent({
  components: {
    IonApp,
    IonRouterOutlet
  },
  data () {
    return {
      standalone: window.matchMedia('(display-mode: standalone)').matches,
      isCordova: window.cordova,
      nodeEnv: process.env.NODE_ENV
    }
  },
  created () {
    document.addEventListener('ionBackButton', (ev) => {
      ev.detail.register(10, () => {
        console.log('Handler was called!')
      })
    })
    window.matchMedia('(display-mode: standalone)').addEventListener('change', (evt) => {
      window.location.reload()
    })
  },
})
</script>
<style>
.appPaddings {
  background-color: inherit;
  overflow: auto;
  padding-left: .3rem;
  padding-right: .3rem;
  position: absolute;
  width: 100%;
  height: 100vh;
}
.rowi {
  display: flex;
}

.columni {
  flex: 33.33%;
  border: .1rem solid lightgrey;
  padding: 0.2rem;
  margin: 0.2rem;
}
.material-icons.materialIcons {
  font-size: 2rem;
  position: relative;
  top: .6rem;
  line-height: 10%;
}
.material-icons.icons-menu {
  font-size: 1rem;
  position: relative;
  top: .4rem;
}
.material-icons.crosshair {
  font-size: 40px;
  color: magenta;
  opacity: .7;
  left: -14px;
  top: -14px;
  z-index: 10000;
  cursor: grab;
}
.material-icons.locate::before { 
  content: "gps_fixed";
}
.marker-pin {
  width: 30px;
  height: 30px;
  border-radius: 50% 50% 50% 0;
  position: absolute;
  transform: rotate(-45deg);
  left: 50%;
  top: 50%;
  margin: -15px 0 0 -15px;
}
.marker-pin::after {
  content: '';
  width: 24px;
  height: 24px;
  margin: 3px 0 0 3px;
/*background: #fff;*/
  position: absolute;
  border-radius: 50%;
}

.custom-div-icon i {
  position: absolute;
  width: 1.7rem;
  font-size: .9rem;
  line-height: .8rem;
  color: white;
  left: 0;
  right: 0;
  margin: 10px auto;
  text-align: center;
}
</style>
