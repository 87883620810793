<template>
  <ion-page>
    <ion-header translucent>
      <ion-toolbar>
        <ion-title>Funde</ion-title>
      </ion-toolbar>
      <ion-toolbar>
        <ion-segment @ionChange="segmentChanged($event)" :value="checked">
          <ion-segment-button value="In Bearbeitung">
            <ion-label>BEARBEITEN
              <ion-badge :style="'--background:'+colorStatus['In Bearbeitung']">{{count["In Bearbeitung"]}}</ion-badge>
            </ion-label>
          </ion-segment-button>
          <ion-segment-button value="Andere Funde">
            <ion-label>UMGEBUNG
              <ion-badge :style="'--background:'+colorStatus['Andere Funde']">{{count["Andere Funde"]}}</ion-badge>
            </ion-label>
          </ion-segment-button>
          <ion-segment-button value="Meine Funde">
            <ion-label>ÜBERMITTELT
              <ion-badge :style="'--background:'+colorStatus['Meine Funde']">{{count["Meine Funde"]}}</ion-badge>
            </ion-label>
          </ion-segment-button>
        </ion-segment>
      </ion-toolbar>
    </ion-header>
    <ion-content>
      <NearBy v-if="checked=='Andere Funde'"/>
      <Samples v-else :status="checked"/>
    </ion-content>
  </ion-page>
</template>

<script>
import { 
  IonSegment,
  IonSegmentButton, 
  IonLabel,
  IonPage,
  IonContent,
  IonHeader,
  IonToolbar,
  IonTitle,
  IonBadge
} from '@ionic/vue'
import NearBy from '../components/Nearby.vue'
import Samples from '../components/Samples.vue'
import { CONFIG } from '../helpers/config.js'

export default {
  inject: ['storage'],
  data () {
    return {
      checked: "In Bearbeitung",
      colorStatus: CONFIG.colorStatus
    }
  },
  computed: {
    count: function () {
      let countSamples = {
        "In Bearbeitung": 0,
        "Meine Funde": 0,
        "Andere Funde": 0,
      }
      for (const sample of Object.values(this.storage.samples)) {
        if (sample.type!="subplot")
          countSamples[sample.status]++
      }
      return countSamples
    }
  },
  components: {
    IonSegment,
    IonSegmentButton,
    IonLabel,
    IonPage,
    IonContent,
    IonHeader,
    IonToolbar,
    IonTitle,
    IonBadge,
    NearBy,
    Samples
  },
  methods: {
    segmentChanged(ev) {
      this.storage.status = this.checked = ev.detail.value
    }
  }
}
</script>
<style scoped>
ion-badge {
  margin-left: -3px;
}
</style>