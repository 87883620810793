<template>
  <div ref="mapElement" style="height:100%"></div>
</template>

<script>
import 'leaflet/dist/leaflet.css'
import 'leaflet/dist/images/marker-icon.png';
import 'leaflet/dist/images/marker-icon-2x.png'
import 'leaflet/dist/images/marker-shadow.png'
import 'leaflet/dist/images/layers-2x.png';
import 'leaflet/dist/images/layers.png'
import 'leaflet.locatecontrol'
import 'leaflet.locatecontrol/dist/L.Control.Locate.min.css'
import 'leaflet-html-legend'
import 'leaflet-html-legend/dist/L.Control.HtmlLegend.css'
import L from 'leaflet';
import { CONFIG } from '../helpers/config.js'
import latlngGraticule from '../helpers/Leaflet.Graticule'
import proj4 from 'proj4'
export default {
  inject: ['storage'],
  name: 'LeafletMap',
  data () {
    return {
      mapZoom: 0,
      samples: this.storage.samples,
      map: null,
      tileLayer: null,
      layers: [],
      pointsGroup : null,
      samplesGroup : null,
      overlayMaps: null,
      lc: null,
      controlLayers: null,
      geoLayer: null
    }
  },
  props: {
    center: {
    },
    zoom: {
      type: Number
    },
    offline: {
      type: Boolean
    },
    pointClick: {
      type: Boolean
    },
    controls: {
    },
    mapid: {
    }
  }, 
  mounted() {
    console.log(this.map+" mounted "+this.mapid)
        this.$emit('componentready', this)
  },
  methods: {
    initMap(secondary) {
      proj4.defs("EPSG:4314","+proj=longlat +ellps=bessel +towgs84=598.1,73.7,418.2,0.202,0.045,-2.455,6.7 +no_defs")
      L.Map.Proj = L.Map.extend({
        containerPointToLatLngProj: function (point) {
          var layerPoint = this.containerPointToLayerPoint(point)
          var latlng = this.layerPointToLatLng(layerPoint)
          var xy = proj4('EPSG:4314','EPSG:4326',[latlng.lng, latlng.lat])
          latlng.lng = xy[0]
          latlng.lat = xy[1]
          return latlng
        },
        latLngProjToContainerPoint: function (latlng) {
          var xy = proj4('EPSG:4314','EPSG:4326',[latlng.lng, latlng.lat])
          latlng.lng = xy[0]
          latlng.lat = xy[1]
          return this.layerPointToContainerPoint(this.latLngToLayerPoint(latlng))
        },
      })
      let voyager = L.tileLayer(
        'https://cartodb-basemaps-{s}.global.ssl.fastly.net/rastertiles/voyager/{z}/{x}/{y}' + (L.Browser.retina ? '@2x.png' : '.png'), {
          crossOrigin: true,
          minZoom: 5,
          maxZoom: 19,
          attribution: '&copy; <a href="http://www.openstreetmap.org/copyright">OpenStreetMap</a>, &copy; <a href="https://voyager.com/attribution">voyager</a>'
      })
      let imagery_light = L.tileLayer(
        'https://server.arcgisonline.com/ArcGIS/rest/services/World_Imagery/MapServer/tile/{z}/{y}/{x}', {
        attribution: 'Tiles &copy; Esri &mdash; Source: Esri, i-cubed, USDA, USGS, AEX, GeoEye, Getmapping, Aerogrid, IGN, IGP, UPR-EGP, and the GIS User Community',
        detectRetina: true,
        crossOrigin: true,
        minZoom: 5,
        maxZoom: 19,
        opacity: 0.5,
      })
      let imagery = L.tileLayer(
        'https://server.arcgisonline.com/ArcGIS/rest/services/World_Imagery/MapServer/tile/{z}/{y}/{x}', {
        attribution: 'Tiles &copy; Esri &mdash; Source: Esri, i-cubed, USDA, USGS, AEX, GeoEye, Getmapping, Aerogrid, IGN, IGP, UPR-EGP, and the GIS User Community',
        detectRetina: true,
        crossOrigin: true,
        minZoom: 5,
        maxZoom: 19,
      })
      let light_all = L.tileLayer(
        'https://{s}.basemaps.cartocdn.com/light_all/{z}/{x}/{y}' + (L.Browser.retina ? '@2x.png' : '.png'), {
          attribution:'&copy; <a href="http://www.openstreetmap.org/copyright">OpenStreetMap</a>, &copy; <a href="https://carto.com/attributions">CARTO</a>',
          crossOrigin: true,
          minZoom: 5,
          maxZoom: 19,
      })
      let OSMtopo = L.tileLayer('https://{s}.tile.opentopomap.org/{z}/{x}/{y}.png',{
          attribution: 'Map data: &copy; <a href="http://www.openstreetmap.org/copyright">OpenStreetMap</a>, <a href="http://viewfinderpanoramas.org">SRTM</a> | Map style: &copy; <a href="https://opentopomap.org">OpenTopoMap</a> (<a href="https://creativecommons.org/licenses/by-sa/3.0/">CC-BY-SA</a>)',
          detectRetina: true,
          crossOrigin: true,
          minZoom: 5,
          maxZoom: 19,
      })
      let topPlusOpen = L.tileLayer("https://sgx.geodatenzentrum.de/wmts_topplus_web_open/tile/1.0.0/web/default/WEBMERCATOR/{z}/{y}/{x}.png",{
      attribution: '<a href="https://opentopomap.org">OpenTopoMap</a> (<a href="https://creativecommons.org/licenses/by-sa/3.0/">CC-BY-SA</a>)',
          detectRetina: true,
          crossOrigin: true,
          minZoom: 5,
          maxZoom: 19,
      })
      let tk25RP = L.tileLayer.wms("https://geo4.service24.rlp.de/wms/rp_dtk25.fcgi?", {
        detectRetina: true,
        layers: "wms_rp_dtk25"
      })
      let tkgrid = new latlngGraticule({
        weight: 1.6,
        color: 'rgb(255, 0, 0)',
        fontcolor: 'rgb(255, 0, 0)',
        zoomInterval: {
          latitude: [
            {start: 8, end: 25, interval: 1/10},
          ],
          longitude: [
            {start: 8, end: 25, interval: 1/6},
          ]
        }
      })
      let tkgrid4 = new latlngGraticule({
        weight: 1.6,
        color: 'rgb(255, 140, 0)',
        fontcolor: 'rgb(255, 140, 0)',
        zoomInterval: {
          latitude: [
            {start: 10, end: 25, interval: 1/20},
          ],
          longitude: [
            {start: 10, end: 25, interval: 1/12},
          ]
        }
      })
      let tkgrid16 = new latlngGraticule({
        color: 'rgb(255, 204, 0)',
        fontcolor: 'rgb(255, 204, 0)',
        zoomInterval: {
          latitude: [
            {start: 12, end: 25, interval: 1/40},
          ],
          longitude: [
            {start: 12, end: 25, interval: 1/24},
          ]
        }
      })
      let tkgrid64 = new latlngGraticule({
        opacity: 1,
        color: 'rgb(255, 255, 0)',
        fontcolor: 'rgb(255, 255, 0)',
        zoomInterval: {
          latitude: [
            {start: 13, end: 25, interval: 1/80},
          ],
          longitude: [
            {start: 13, end: 25, interval: 1/48},
          ]
        }
      })
      let minutes = new latlngGraticule({
        color: 'rgb(255, 204, 0)',
        fontcolor: 'rgb(200, 204, 200)',
        zoomInterval: [
            {start: 12, end: 25, interval: 1/60}
            ]
      })
      let radioMaps = {
        "Reisekarte": voyager,
        "Helle Karte": light_all,
        "Satellitenbild": imagery,
        "Open Topo": OSMtopo,
        "TopPlusOpen": topPlusOpen,
        "TK25 Rhl.-Pf.": tk25RP,
      }
      this.overlayMaps = {
        "Satellitoverlay": imagery_light,
      }
      if (this.storage.geoJSON.geoJSON) {
        this.geoLayer = this.layerGeoJSON(this.storage.geoJSON.geoJSON)
        this.overlayMaps[this.storage.geoJSON.name] = this.geoLayer
      }
      this.layers.push(radioMaps[this.storage.config.baselayer])
      this.storage.config.overlays.forEach(overlay => {
        this.layers.push(this.overlayMaps[overlay])
      })
      console.log(this.mapid+" before new map "+secondary)
      this.map = new L.Map.Proj(this.$refs['mapElement'], {tap: false, layers:this.layers, dragging:this.controls, preferCanvas:true, zoomAnimation:false})
      if (this.storage.globalSettings.gridType!=8) {
        if (this.storage.globalSettings.gridType==9) {
          this.map.addLayer(minutes)
          this.map.addLayer(tkgrid4)
        }
        if (this.storage.globalSettings.gridType==0) {
          this.map.addLayer(tkgrid64)
          this.map.addLayer(tkgrid16)
          this.map.addLayer(tkgrid4)
        }
        if (this.storage.globalSettings.gridType==1) {
          this.map.addLayer(tkgrid16)
          this.map.addLayer(tkgrid4)
        }        
        if (this.storage.globalSettings.gridType==2) {
          this.map.addLayer(tkgrid4)
        }        
        this.map.addLayer(tkgrid)
      }
      this.map.on('baselayerchange', e => {
        this.storage.config.baselayer = e.name
        this.configMapView()
        this.map.remove()
        this.layers = []
        this.initMap()
      }) 
      this.map.on('overlayadd', e => {
        this.storage.config.overlays.push(e.name)
      }) 
      this.map.on('overlayremove', e => {
        let i = this.storage.config.overlays.indexOf(e.name)
        if (i>-1)
          this.storage.config.overlays.splice(i, 1)
      }) 
      this.map.setView(this.storage.config.mapView.center, this.storage.config.mapView.zoom)
      if (this.storage.points != null && this.pointClick)
        this.mapPoints()
      if (this.storage.samples != {} && this.pointClick)
        this.mapSamples()
      this.controlLayers = L.control.layers(radioMaps, this.overlayMaps, {collapsed: true}).addTo(this.map)
      L.control.scale({imperial: false, position: 'topright'}).addTo(this.map)
      if (this.mapid=="nearby") {
        let elements = []
        for(let [key, status] of Object.entries(CONFIG.colorStatus)) {
          elements.push({
            html:
            `<svg width="20" height="15" viewBox="-10 -10 20 20"><circle cx="-2" cy="0" r="8" stroke="${status}" stroke-width="4" ${key=="Bereits erfasst"?"stroke-dasharray='4'":''} fill="none"/></svg><span>${key}</span>`
          })
        }
        L.control.htmllegend({
          legends: [{
            name: 'Fundstatus',
            elements: elements
          }],
          position: 'topright',
        }).addTo(this.map)
      }
      if (this.controls) {
        this.lc = L.control.locate({
          position: 'topleft',
          strings: {title: "Wo bin ich?"},
          icon: "fas fa-crosshairs",
          setView: "untilPan",
          flyTo: true,
          locateOptions: {enableHighAccuracy: true, maxZoom: 19}
        }).addTo(this.map)
      }
      else {
        this.map.removeControl(this.controlLayers)
      }
      if (!secondary) {
        console.log("componentready")
        this.$emit('componentready', this)
      }
    },
    configMapView() {
      this.storage.config = {
        ...this.storage.config,
        ...{mapView: {center: this.map.getCenter(), zoom: this.map.getZoom()}}
      }
    },
    layerGeoJSON(geojson) {
      let geojsonMarkerOptions = {
          radius: 10,
          color: "#ff7800",
          opacity: 0.5,
          fillColor: "white",
          fillOpacity: 0,
          weight: 2,
      }
      let geoLayer = L.geoJSON(geojson, {
        style: function(feature) {
            switch (feature.geometry.type) {
                case 'MultiLineString': return {color:"#0099ff", weight:1};
                case 'Polygon': return {color:"#00ff99", weight:1,fillOpacity:0};
                case 'MultiPolygon': return {color:"#00ff99", weight:1,fillOpacity:0};
            }
        },
        pointToLayer: function (feature, latlng) {
          return L.circle(latlng, geojsonMarkerOptions)
        },
        onEachFeature: function(feature, layer) {
          if (feature.properties) {
            layer.bindPopup(Object.keys(feature.properties).map(function(k) {
              return k + ": " + feature.properties[k]
            }).join("<br />"), {
              maxHeight: 200
            })
          }
        }
      })
      return geoLayer
    }
 }
}
</script>

<style>
.input-button {
  background-color:rgb(185, 220, 233);
  cursor: pointer;
  border: .1rem solid grey;
  border-radius: 1rem;
  margin-right: 1rem;
  margin-top: .7rem;
  padding-top: .2rem;
  padding-bottom: .2rem;
  padding-right: .4rem;
  padding-left: .4rem;
  font-size: 1rem;
}
#topinfo {
  background-color: rgba(255, 247, 213, 0.514);
  z-index: 1001;
  top: 10%;
  right: 25%;
  left: 18%;
  padding: 1%;
  position: absolute;
}
#bottominfo {
  z-index: 1001;
  width: 96%;
  top: 85%;
  left: 1%;
  padding: 1%;
  text-align: center;
  position: absolute;
}
.leaflet-top, .leaflet-right, .leaflet-left{
  margin-top: .3rem;
  margin-right: .3rem;
  margin-left: .3rem;
}
.savetiles {
  font-size: 1.3rem;
}
.leaflet-control-locate a {
  font-size: 1.5rem;
}
.leaflet-html-legend {
  width: auto;
  padding-right: 0;
  padding-left: 0;
  padding-top: 3px;
  padding-bottom: 0;
  overflow: hidden;
}
.leaflet-touch .leaflet-bar a {
    width: 2rem;
    height: 2rem;
    line-height: 2rem;
}.symbol {
  font-size: .8rem;
  margin-right: 0 !important;
}
</style>