<template>
  <ion-page>
    <ion-header>
      <ion-toolbar color="primary" style="--padding-end:0">
        <ion-buttons slot="start">
          <ion-back-button text="" defaultHref="/"></ion-back-button>
        </ion-buttons>
        <ion-title>Karte</ion-title>
      </ion-toolbar>
      <ion-grid>
        <ion-row class="ion-align-items-start">
          <ion-col size="7" class="ion-align-self-start">
            <ion-item>
              <ion-label position="stacked">Koordinaten</ion-label>
              <ion-input type="text" v-model="coords" @input="inputCoords"/>
            </ion-item>
          </ion-col>
          <ion-col size="5">
            <ion-item>
              <ion-label position="stacked">{{storage.globalSettings.gridType==9?"Minutenfeld (QXY)":"Quadrant (QQQ)"}}</ion-label>
              <ion-input readonly :value=tkTxt />
            </ion-item>
          </ion-col>
        </ion-row>
      </ion-grid>
    </ion-header>
    <ion-fab vertical="bottom" horizontal="end" slot="fixed">
      <ion-fab-button @click="posOK">
        <ion-icon :icon="checkmarkDoneOutline"></ion-icon>
      </ion-fab-button>
    </ion-fab>
    <ion-content>
      <div style="height:100%">
        <LeafletMap
          :center="center?center:storage.config.mapView.center" :pointClick="false"
          :zoom="storage.config.mapView.zoom" :offline="false"
          :controls="true" :mapid="'map'" @componentready="setComponent"/>
      </div>
    </ion-content>
  </ion-page>
</template>

<script>
import {
  IonContent,
  IonHeader,
  IonPage,
  IonTitle,
  IonToolbar,
  IonFab,
  IonFabButton,
  IonIcon,
  IonInput,
  IonLabel,
  IonText,
  IonGrid,
  IonRow,
  IonCol,
  IonItem,
  IonButtons,
  IonBackButton
} from '@ionic/vue'
import { 
  checkmarkDoneOutline,
} from 'ionicons/icons'
import LeafletMap from '../components/LeafletMap.vue'
import L from 'leaflet';
import { useRoute } from 'vue-router'
import { CONFIG } from '../helpers/config.js'
import utilsMixin from '../mixins/utils.js'
delete L.Icon.Default.prototype._getIconUrl
L.Icon.Default.mergeOptions({
  iconRetinaUrl: require('leaflet/dist/images/marker-icon-2x.png'),
  iconUrl: require('leaflet/dist/images/marker-icon.png'),
  shadowUrl: require('leaflet/dist/images/marker-shadow.png'),
})

let coordInput = false

export default {
  components: {
    IonContent,
    IonHeader,
    IonPage,
    IonToolbar,
    IonFab,
    IonIcon,
    IonTitle,
    IonInput,
    IonLabel,
    IonText,
    IonGrid,
    IonRow,
    IonCol,
    IonItem,
    IonFabButton,
    IonButtons,
    IonBackButton,
    LeafletMap,
  },
  inject: ['storage'],
  mixins: [utilsMixin],
  props: {
    center: {},
    zoom: {
      type: Number
    },
  },
  data () {
    return {
      route: useRoute(),
      sampleId: null,
      occId: null,
      coords: '',
      mapZoom: 0,
      mapComponent: null,
      sample: this.storage.samples[useRoute().params.sampleId],
      action: "Position übernehmen",
      crosshair: null,
      polylineGroup: null,
      tkTxt: '',
      checkmarkDoneOutline

    }
  },
  ionViewWillEnter() {
    this.sampleId = this.route.params.sampleId
    this.sample = this.storage.samples[this.sampleId]
    this.occId = this.route.params.occId
    this.setComponent(this.mapComponent)
  },
  ionViewDidEnter() {
    this.mapComponent.map.invalidateSize()
  },
  methods: {
    setComponent(component) {
      if (!component.map)
        component.initMap(true)
      this.mapComponent = component
      let map = component.map
      if (component.occsGroup) {
        component.occsGroup.clearLayers()
        component.occsGroup.remove()
      }
      if (this.polylineGroup != null) {
        this.mapComponent.map.removeLayer(this.polylineGroup)
      }
      this.polylineGroup = L.featureGroup().addTo(map)
      let latLng = map.getCenter()
      this.coords = latLng.lng.toFixed(5)+", "+latLng.lat.toFixed(6)
      let myIcon = L.divIcon({className: 'material-icons crosshair', html: 'center_focus_weak'})
      if (this.crosshair != null) {
        this.mapComponent.map.removeLayer(this.crosshair)
      }
      this.crosshair = L.marker(latLng, {icon: myIcon})
      this.crosshair.addTo(map)
      map.on('move', () => {
        latLng = map.getCenter()
        this.crosshair.setLatLng(latLng)
        if(!coordInput) {
          this.coords = latLng.lng.toString().substr(0,8)
            +", "+latLng.lat.toString().substr(0,9)
        }
        if (this.storage.globalSettings.gridType==9)
          this.tkTxt = this.projMinutes(latLng.lng, latLng.lat)
        else
          this.tkTxt = this.projTK(latLng.lng, latLng.lat)
      })
      map.on('moveend', () => {
        coordInput = false 
      })
      map.on('locationerror', (e) => {
        console.log(e)
        component.lc.stop()
      })
      let samples = []
      if (this.sample.parentId) {
        let sample = this.storage.samples[this.sample.parentId]
        if(sample.polyline.length==1) {
          let circle = L.circle([sample.yCentroid, sample.xCentroid], {
            radius: sample.blur?sample.blur/2:10,
            color: 'orange',
            fillOpacity: 0,
            fillColor : 'white'})
            .addTo(this.polylineGroup)
          circle.ID = sample.id
        }
        if(sample.polyline.length>1) {
          let  options = {color: 'blue', lineCap: 'butt', weight: 3, opacity: .3}
          L[sample.geoType](sample.polyline, options).addTo(this.polylineGroup)
        }
        samples = this.storage.samples[this.sample.parentId].samples
      }
      else if (this.sample.type=="singleocc")
        samples.push(this.sample.id)
      this.occsMarkers(samples, component, this.sample.id)
      if(this.sample.xCentroid) {
        map.setView([this.sample.yCentroid, this.sample.xCentroid], 17)
      }
      this.action = "Fundpunkt setzen"
    },
    inputCoords (e) {
      let pos = e.target.value.indexOf(', ')
      let latLng = [e.target.value.substring(pos+2),
        e.target.value.substring(0, pos)]
      coordInput = true
      this.mapComponent.map.setView(latLng, this.mapComponent.map.getZoom())
    },
    async posOK () {
      let confirmation = {}, gridType = this.storage.globalSettings.gridType
      confirmation.role = "ok"
      if (gridType==9)
        gridType = 0
      console.log(this.tkTxt.slice(0, this.tkTxt.length-gridType), this.storage.lastTK, this.tkTxt)
      if (this.storage.globalSettings.gridChange&&this.storage.lastTK&&this.tkTxt.slice(0, this.tkTxt.length-gridType)!=
        this.storage.lastTK.slice(0, this.tkTxt.length-gridType))
      confirmation = await CONFIG.headerAlert("Wechsel des in den Optionen ausgewählten Rasterfeldtyps seit letzter Artbeobachtung, Position trotzdem speichern?", "", [{text:'Abbruch'},{text:'OK',role:'ok'}])
      if (confirmation.role==="ok") {
        let pos = this.coords.indexOf(', ')
        this.storage.samples[this.sampleId]["xCentroid"] = this.coords.substring(0, pos)
        this.storage.samples[this.sampleId] ["yCentroid"] = this.coords.substring(pos+2)
        if (this.sample.type=="plot"&&localStorage.getItem('surveyIndicia')==82) {
          for (const sample of this.sample.samples) {
            this.storage.samples[sample].xCentroid = this.sample.xCentroid
            this.storage.samples[sample].yCentroid = this.sample.yCentroid
          }
        }
        this.sample.blur = this.sample[12] = this.sample[26] = this.sample[60] = null
        this.sample[61] = "map"
        this.sample.tk = this.storage.lastTK = this.tkTxt
        this.$router.push({name: 'singleocc', params: {sample: this.sampleId, occ: this.occId}})
      }
    },
  }
}
</script>
<style scoped>
ion-item {
  --padding-start: 0;
  --inner-padding-top: 0;
}
ion-grid {
  --ion-grid-padding: 0;
}
</style>