/* eslint-disable no-console */

import { register } from 'register-service-worker'
import { CONFIG } from './helpers/config.js'

if (process.env.NODE_ENV === 'production' && window.matchMedia('(display-mode: standalone)').matches) {
  register(`${process.env.BASE_URL}service-worker.js`, {
    ready () {
      console.log(
        'App is being served from cache by a service worker.\n' +
        'For more details, visit https://goo.gl/AFskqB'
      )
    },
    registered () {
      console.log('Service worker has been registered.')
    },
    cached () {
      console.log('Content has been cached for offline use.')
      CONFIG.headerAlert("Inhalte wurden heruntergeladen",
        "App funktioniert ab jetzt auch offline", [{text:'OK',role:'ok'}])
        .then( () => {
          window.location.reload()
        })
    },
    updatefound () {
      console.log('New content is downloading.')
    },
    updated (registration) {
      console.log('New content is available; please refresh.')
      registration.waiting.postMessage({ type: 'SKIP_WAITING' })
      CONFIG.headerAlert("Update der App wird installiert",
      "Neuerungen unter Lizenzen",
      [{text:'OK',role:'ok'}])
      .then(async (result) => {
        if (result.role=='ok') {
          window.location.reload()
        }
      })
    },
    offline () {
      console.log('No internet connection found. App is running in offline mode.')
    },
    error (error) {
      console.error('Error during service worker registration:', error)
    }
  })
}
document.onvisibilitychange = () => {
  if(!document.hidden) {
    if ('serviceWorker' in navigator) {
      navigator.serviceWorker.ready.then(registration => {
        registration.update().then(() => {
          console.log("update checked")
        })
      })
    }
  }
}