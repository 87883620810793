import { alertController } from '@ionic/vue'

async function headerAlert(header, message, buttons) {
  const alert = await alertController
    .create({
      header: header,
      message: message,
      buttons: buttons,
    })
  alert.present()
  return alert.onDidDismiss()
}
let api = process.env.NODE_ENV === 'production'?process.env.VUE_APP_API+'/api/v1':'http://localhost:8080/api/v1'

function resize(data, fileType, MAX_WIDTH, MAX_HEIGHT) {
  const promise = new Promise((fulfill) => {
    const image = new window.Image() // native one
    image.onload = () => {
      if (image.width>MAX_WIDTH || image.height>MAX_HEIGHT) {
        let width = image.width
        let height = image.height
        const maxWidth = MAX_WIDTH || width
        const maxHeight = MAX_HEIGHT || height
        let res = null
        // resizing
        if (width > height) {
          res = width / maxWidth
        } else {
          res = height / maxHeight
        }
        width /= res
        height /= res
        // Create a canvas with the desired dimensions
        const canvas = document.createElement('canvas')
        canvas.width = width
        canvas.height = height
        // Scale and draw the source image to the canvas
        canvas.getContext('2d').drawImage(image, 0, 0, width, height)
        // Convert the canvas to a data URL in some format
        fulfill(canvas.toDataURL(fileType, 0.9))
      }
      else {
        fulfill(data)
      }
    }
    image.src = data
  })
  return promise;
}
const licences = {
  "@fortawesome/fontawesome-free": "^5.5.0",
  "axios": "^0.18.0",
  "leaflet": "^1.3.4",
  "leaflet-html-legend": "^0.3.4",
  "leaflet.locatecontrol": "^0.65.1",
  "localforage": "^1.7.2",
  "localforage-getitems": "^1.4.2",
  "luxon": "^1.4.1",
  "nprogress": "^0.2.0",
  "sweetalert2": "^7.28.11",
  "typeface-asap-condensed": "0.0.72",
  "vue": "^2.5.17",
  "vue-autosuggest": "^1.7.3",
  "vue-awesome-swiper": "^3.1.3",
  "vue-datetime": "^1.0.0-beta.6",
  "vue-js-toggle-button": "^1.3.1",
  "vue-numeric-input": "^1.0.4",
  "vue-router": "^3.0.1",
  "vue-slider-component": "^2.8.2",
  "weekstart": "^1.0.0"
}
const colors = [
  'mediumaquamarine',
  'indianred',
  'seagreen',
  'orange',
  'cornflowerblue',
  'darkkhaki',
  'darkviolet',
  'darkturquoise',
]
const colorStatus = {
  "In Bearbeitung": "red",
  "Meine Funde": "green",
  "Andere Funde": "blue",
}
function UUID() {
  return 'xxxxxxxx-xxxx-6xxx-yxxx-xxxxxxxxxxxx'.replace(/[xy]/g, (c) => {
    const r = Math.random() * 16 | 0
    const v = c === 'x' ? r : (r & 0x1 | 0x6)
    return v.toString(16)
  })
}
export const CONFIG = {
  api,
  headerAlert,
  resize,
  licences,
  colors,
  colorStatus,
  UUID
}
