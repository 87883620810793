import { createRouter, createWebHashHistory } from '@ionic/vue-router'
import RadioList from './views/RadioList.vue'
import Map from './views/Map.vue'
import MapOcc from './views/MapOcc.vue'
import SpeciesSearch from './views/Speciessearch.vue'
import SingleOcc from './views/Singleocc.vue'
import Tabs from './views/Tabs.vue'
import Funde from './views/SegmentsFunde.vue'

export default createRouter({
  history: createWebHashHistory(),
  base: process.env.BASE_URL,
  scrollBehavior () {
    return { x: 0, y: 0 }
  },
  routes: [
    {
      path: '/map/:id',
      name: 'map',
      component: Map,
      props: true
    },
    {
      path: '/mapocc/:sampleId/:occId',
      name: 'mapocc',
      component: MapOcc,
      props: true
    },
    { 
      path: '/radiolist/:id/:attribute/:occ',
      name: 'radiolist',
      component: RadioList,
      props: true
    },
    {
      path: '/singleocc/:sample/:occ',
      name: 'singleocc',
      component: SingleOcc,
      props: true
    },
    {
      path: '/speciessearch/:sample/:occ',
      name: 'speciessearch',
      component: SpeciesSearch,
      props: true
    },
    {
      path: '/',
      redirect: '/tabs/eingabe',
    },
    {
      path: '/tabs/',
      name: 'tabs',
      component: Tabs,
      children: [
        {
          path: '',
          redirect: 'sonstiges',
        },
          {
          path: 'eingabe',
          name: 'eingabe',
          component: () => import('@/views/Eingabe.vue'),
        },
        {
          path: 'funde',
          name: 'funde',
          component: Funde,
        },
        {
          path: 'sonstiges',
          name: 'sonstiges',
          component: () => import('@/views/Settings.vue'),
        },
          ],
  
    },
  ]
})
