import { CONFIG } from '../helpers/config.js'
import localForage from 'localforage'
export default {
  methods: {
    createSample: function (type, parent) {
      let sample = {}
      sample.samples = []
      sample.type = type
      sample.id = CONFIG.UUID()
      sample.status = "In Bearbeitung"
      if (parent) {
        sample.parentId = parent.id
        parent.samples.push(sample.id)
        sample.location_name = parent.location_name
        sample.recorder_names = parent.recorder_names
        if (this.storage.attributes[0].attributes[0].lock)
          sample.date = this.storage.attributes[0].attributes[0].lock
        else
          sample.date = new Date().toJSON()
      }
      else {
        for(const group of Object.values(this.storage.attributes)) {
          for(const attribute of Object.values(group.attributes)) {
            if (attribute.attributeType=='S') {
              if (attribute.controlType=='hierarchical_select'||
                  attribute.controlType=='checkbox_group'||
                  attribute.controlType=='listbox') {
                if (attribute.lock)
                  sample[attribute.attrName] = attribute.lock
                else
                  sample[attribute.attrName] = []
              }
              else {
                if (attribute.lock)
                  sample[attribute.attrName] = attribute.lock
                else {
                  if (attribute.controlType=="date")
                    sample[attribute.attrName] = new Date().toJSON()
                  else
                    sample[attribute.attrName] = null
                }
              }
            }
          }
        }
      }
      sample.occurrences = {}
      sample.fotos = {}
      sample.polyline = []
      this.storage.samples[sample.id] = sample
      return sample
    },
    async deleteSampleQuiet(sample) {
      for(const key of Object.keys(sample.fotos)) {
        localForage.removeItem('img'+key)
      }  
      for(const occ of Object.values(sample.occurrences)) {
        for(const key of Object.keys(occ.fotos)) {
          localForage.removeItem('img'+key)
        }
      }
      if (sample.type=="subplot") {
        let arr = this.storage.samples[sample.parentId].samples
        console.log(arr.splice(arr.indexOf(sample.id), 1))
      }
      let subSamples = []
      for (const subSample of sample.samples) {
        subSamples.push(subSample)
      }
      for (const subSample of subSamples) {
        await this.deleteSampleQuiet(this.storage.samples[subSample])
      }
      delete this.storage.samples[sample.id]
    },
    async deleteSample(sampleId) {
      let sample = this.storage.samples[sampleId]
      console.log(sample)
      return new Promise(async (resolve) => {
        if (sample.status=='In Bearbeitung') {
          CONFIG.headerAlert("Erhebung vom Gerät entfernen?",
          "Bereits übermittelte Erhebungen bleiben wiederabrufbar",
          [{text:'Abbruch'},{text:'OK',role:'ok'}])
          .then(async (result) => {
            if (result.role==="ok") {
              await this.deleteSampleQuiet(sample)
              localForage.setItem("samples",  JSON.parse(JSON.stringify(this.storage.samples)))
              resolve("OK")
            }
            else {
              resolve("Abbruch")
            }
          })
        } 
        else {
          delete this.storage.samples[sampleId]
          localForage.setItem("samples",  JSON.parse(JSON.stringify(this.storage.samples)))
          resolve("OK")
        }
      })
    },
    deleteSamples(status) {
      for (const sample of Object.values(this.storage.samples)) {
        if (sample.status==status) {
          for(const key of Object.keys(this.storage.samples[sample.id].fotos)) {
            localForage.removeItem('img'+key)
          }  
          delete this.storage.samples[sample.id]
        }
      }
      localForage.setItem("samples",  JSON.parse(JSON.stringify(this.storage.samples)))
    },
    createOcc: function (sample) {
      let occurrence = {}
      occurrence.sampleId = sample.id
      occurrence.id = CONFIG.UUID()
      occurrence.fotos = {}
      for(const group of Object.values(this.storage.attributes)) {
        for(const attribute of Object.values(group.attributes)) {
          if (attribute.attributeType=='O') {
            if (attribute.controlType=='hierarchical_select'||
                attribute.controlType=='checkbox_group'||
                attribute.controlType=='listbox') {
              if (attribute.lock)
                occurrence[attribute.attrName] = attribute.lock
              else
                occurrence[attribute.attrName] = []
            }
            else {
              if (attribute.lock)
                occurrence[attribute.attrName] = attribute.lock
              else
                occurrence[attribute.attrName] = null
            }
          }
        }
      }
      occurrence.speciesId = null
      occurrence.speciesName = ""
      occurrence.sensitivity_precision = 0
      sample.occurrences[occurrence.id] = occurrence
      return occurrence
    },
    deleteOccurrence(sample, occ) {
      CONFIG.headerAlert("Artvorkommen "+occ+" vom Gerät entfernen?",
        "Bereits übermittelte Artvorkommen bleiben wiederabrufbar",
        [{text:'Abbruch'},{text:'OK',role:'ok'}])
          .then((result) => {
          if (result.role=="ok") {
            for(const key of Object.keys(this.storage.samples[sample].occurrences[occ].fotos)) {
              localForage.removeItem('img'+key)
            }  
            delete this.storage.samples[sample].occurrences[occ]
            localForage.setItem("samples",  JSON.parse(JSON.stringify(this.storage.samples)))
            this.$router.push({name: 'sample', params: {id: sample}})
          }
        })
    },
  }
}
