<template>
    <ion-content>
      <ion-header>
        <ion-toolbar color="secondary" style="--padding-end:0">
          <ion-buttons slot="start">
            <ion-button @click="modalController.dismiss()">
              <ion-icon slot="icon-only" :icon="arrowBackOutline" />
            </ion-button>
          </ion-buttons>
          <ion-title>{{data.attr.text}}</ion-title>
        </ion-toolbar>
      </ion-header>
      <ion-fab vertical="bottom" horizontal="end" slot="fixed">
        <ion-fab-button @click="modalController.dismiss()">
          <ion-icon :icon="checkmarkDoneOutline"></ion-icon>
        </ion-fab-button>
      </ion-fab>
      <ion-fab vertical="bottom" horizontal="start" slot="fixed">
        <ion-fab-button @click="data.valueObject[data.attr.attrName]=null">
          <ion-icon :icon="trashBinOutline"></ion-icon>
        </ion-fab-button>
      </ion-fab>
      <ion-item ref="itemRef">
        <ion-label position="stacked">{{data.attr.text}}</ion-label>
        <ion-textarea :inputmode="data.attr.type=='I'?'numeric':'text'" id="editRef" :placeholder="data.attr.type=='I'?'Zahl...':'Text...'" :value="data.valueObject[data.attr.attrName]" @ionChange="textChange" autoGrow clearInput />      
      </ion-item>
    </ion-content>
</template>
<script>
import { CONFIG } from '../helpers/config.js'
import { modalController } from "@ionic/vue"
import {
  IonHeader,
  IonTitle,
  IonToolbar,
  IonInput,
  IonFab,
  IonFabButton,
  IonIcon,
  IonButtons,
  IonButton,
  IonContent,
  IonTextarea,
  IonItem,
  IonLabel
} from '@ionic/vue'
import { 
  trashBinOutline,
  checkmarkDoneOutline,
  arrowBackOutline
} from 'ionicons/icons'
export default {
  components: {
    IonHeader,
    IonTitle,
    IonToolbar,
    IonInput,
    IonFab,
    IonFabButton,
    IonIcon,
    IonButtons,
    IonButton,
    IonContent,
    IonTextarea,
    IonItem,
    IonLabel
  },
  inject: ['storage'],
  props: {
    data: Object,
  },
  data () {
    return {
      trashBinOutline,
      checkmarkDoneOutline,
      arrowBackOutline,
      modalController: modalController
    }
  },
  methods: {
    textChange (event) {
      if (this.data.attr.type=='I') {
        //      const regex = new RegExp("^[+-]?[0-9]*[\.]?[0-9]*$")
        const regex = new RegExp("^[-]?[0-9]*$")
        if (regex.test(event.target.value)) {
          this.data.valueObject[this.data.attr.attrName] = event.target.value
          this.inheritValue("location_name")
          this.inheritValue("recorder_names")
        }
        else {
          CONFIG.headerAlert("Eingabefehler", "Nur Zahlen erlaubt", [{text:'OK'}])
        }
      }
      else {
        this.data.valueObject[this.data.attr.attrName] = event.target.value
        this.inheritValue("location_name")
        this.inheritValue("recorder_names")
      }
    },
    inheritValue(attr) {
      if (this.data.attr.attrName==attr) {
        for (const sample of this.data.valueObject.samples) {
          this.storage.samples[sample][attr] = this.data.valueObject[this.data.attr.attrName]
        }
      }
    }
  }
}
</script>
<style scoped>
</style>